import React from 'react';
import '../assets/css/styles.css';
import MenuCatering from '../assets/img/MENU_CATERING_MesaDeTrabajo1.png';
import MenuMesa from '../assets/img/menu_MesaDeTrabajo1.png';

const Home = () => {
    return(
        <>
        <div className='pagina'>
            <div className='contenedor imageCarousel'>
                <img className='menuCatering' src={MenuCatering} alt='Menu Catering'></img>
                <img className='menuMesa' src={MenuMesa} alt='Menu Mesa'></img>
            </div>
        </div>
        </>
    )
}

export default Home;