import React from 'react';
import logo from '../assets/img/logoCuadrado.jpg';
import '../assets/css/styles.css';

function NavBar() {

  return (
    <div>
      <nav class="navbar NavbarStyle">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={logo} className='LogoNavbar' alt='Logo'></img>
          </a>
          <h2 className='texto'>El Torogoz Seattle</h2>
          <div>
            
          </div>
          {/* <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">MENU</h5>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">Our Services</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Testimonials</a>
                </li>
                <li class="nav-item">
                <a class="nav-link" href="#">About us</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Contact Us</a>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </nav>
    </div>
  );
}

export default NavBar;