import React from 'react';
import '../assets/css/styles.css';
import facebook from '../assets/img/facebook.png';
import instagram from '../assets/img/instagram.png';
import telefono from '../assets/img/phone-call.png';
import whatsapp from '../assets/img/whatsapp.png';
import mapa from '../assets/img/alfiler.png';

const Footer = () => {
    return(
        <>
        <div>
            <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top'>
                <p className='col-md-4 mb-0 text-muted'>© El Torogoz Seattle</p>
                <div className='align-items-center justify-content-center'>
                    
                </div>
                <ul className='nav col-md-4 justify-content-end'>
                    <li className='nav-item'>
                        <a href='https://www.facebook.com/share/RpiEY9hFq61WfbvB/?mibextid=LQQJ4d' target= "_blank" rel="noreferrer" className='nav-link px-2 text-muted'>
                            <img src={facebook} className='iconos' alt="Facebook"/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='https://www.instagram.com/pupuseriaeltorogozseattle?igsh=MTE2Nng5Y2phMnNqeg%3D%3D&utm_source=qr' target= "_blank" rel="noreferrer" className='nav-link px-2 text-muted'>
                            <img src={instagram} className='iconos' alt="Instagram"/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a class="nav-link px-2 text-muted" href="tel:+12066737121">
                            <img src={telefono} className='iconos' alt="Telefono"/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a class="nav-link px-2 text-muted" href="https://wa.me/+12066737121" target= "_blank" rel="noreferrer" >
                            <img src={whatsapp} className='iconos' alt="Whatsapp"/>
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a class="nav-link px-2 text-muted" href="https://www.google.com/maps/place/El+Centro+de+la+Raza+(The+Center+for+People+of+All+Races)/@47.5788686,-122.3116103,127a,35y,1.28h,45t/data=!3m1!1e3!4m6!3m5!1s0x54906a865af8bce1:0x368f21b1f648e378!8m2!3d47.580286!4d-122.311447!16s%2Fg%2F1tjgp8ym?entry=ttu" target= "_blank" rel="noreferrer" >
                            <img src={mapa} className='iconos' alt="Maps"/>
                        </a>
                    </li>
                </ul>
            </footer>
        </div>
        </>
    )
}

export default Footer;